<script>
import { mapActions } from "vuex";
import IconBase from "../components/icons/_icon-base.vue";
import iconTim from "../components/icons/_icon-tim.vue";
import getDomainProjectType from "@utils/getDomainProjectType";

export default {
  name: "Vendedor",
  components: {
    IconBase,
    iconTim,
  },

  async mounted() {
    let vendedor = this.$route.query.vendedor;
    const tipoProjeto =
      getDomainProjectType(window.location.hostname) || "TIM LIVE";
    const sellers = await this.getSellers(
      tipoProjeto === "TimDealer" ? "dealer" : "oficial"
    );

    const isValidSeller = !!sellers.find(val => val.value == vendedor);
    vendedor = isValidSeller ? vendedor : undefined;

    this.changeSeller(vendedor);
    this.setSellers(sellers);

    return this.$router.push({
      path: "/",
      query: { ...this.$route.query, vendedor, sellerMode: true },
    });
  },
  methods: {
    ...mapActions({
      fetchApi: "api",
      setSellers: "catalog/setSellers",
      changeSeller: "changeSeller",
    }),

    async getSellers(project) {
      const response = await this.fetchApi({
        entity: "timcheckout",
        action: "sellers",
        params: { project },
      });

      if (response.data.success) {
        const sellers = response.data.sellers;
        return sellers
          .map(val => ({
            value: val.id,
            text: val.name,
          }))
          .toSorted((a, b) => {
            if (a.text > b.text) return 1;
            if (a.text < b.text) return -1;
            return 0;
          });
      }
    },
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-primary"
  >
    <icon-base :size="16"><icon-tim /></icon-base>
  </div>
</template>
