import toKebabCase from "@utils/to-kebab-case";
import isMobileDevice from "@utils/is-mobile-device";
import stringSanitize from "@utils/string-sanitize";

const inBrowser = typeof window !== "undefined";

export default class Events {
  pushDataLayer(obj) {
    window.dataLayer || [];
    window.dataLayer?.push(obj);
  }
  noPushDataLayer(obj) {
    return obj || null;
  }
  sendPageView(route, nodes = {}) {
    return this.noPushDataLayer({
      event: "pageView",
      page: {
        pagePath: route.path,
        name: toKebabCase(route.name),
        area: "b2c",
        segment: "ecommerce",
        businessFront: "movel",
        category: "transbordo-pos-familia",
        subCategory: route.ds,
        environment: `b2c-checkout-movel-${
          isMobileDevice() ? "mobile" : "web"
        }`,
        platform: "shinobi:1.0.0",
      },
      ...nodes,
    });
  }
  pushStep(step, products = []) {
    return this.noPushDataLayer({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step,
          },
          products,
        },
      },
    });
  }
  dispatchEventClickBtn(ds) {
    return this.noPushDataLayer({
      event: "interaction",
      interaction: {
        component: "button",
        action: "click:button",
        step: "",
        description: ds,
        value: "0",
      },
    });
  }
  bannerConversion(ds) {
    return this.noPushDataLayer({
      event: "noninteraction",
      noninteraction: {
        component: "page",
        action: "view:banner",
        step: "",
        description: `conversion:${ds}`,
        value: "0",
      },
    });
  }
  dispatchEventError(ds) {
    return this.pushDataLayer({
      event: "eventError",
      interaction: {
        component: "error-msg",
        action: "view:error",
        step: "",
        description: stringSanitize(ds),
        value: "0",
      },
    });
  }
  addToCart(products = [{}]) {
    return this.noPushDataLayer({
      event: "addToCart",
      ecommerce: {
        currencyCode: "BRL",
        add: {
          products,
        },
      },
    });
  }
  removeFromCart(products = [{}]) {
    return this.noPushDataLayer({
      event: "removeFromCart",
      ecommerce: {
        currencyCode: "BRL",
        remove: {
          products,
        },
      },
    });
  }
  purchase(
    {
      id = null,
      affiliation = null,
      revenue = null,
      tax = null,
      shipping = null,
    } = {},
    products = [{}]
  ) {
    return this.noPushDataLayer({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: id || "",
            affiliation: affiliation || "b2c-ecommerce-movel-transbordo",
            revenue: revenue || "0.0",
            tax: tax || "0.0",
            shipping: shipping || "0.0",
          },
          products,
        },
      },
    });
  }
  promotionView({
    id = null,
    name = null,
    creative = null,
    position = null,
  } = {}) {
    return this.noPushDataLayer({
      event: "promotionView",
      ecommerce: {
        promoView: {
          promotions: [
            {
              id: id,
              name: name,
              creative: creative,
              position: position,
            },
          ],
        },
      },
    });
  }
  onPromoClick(e, banner = {}) {
    return this.pushDataLayer({
      event: "promotionClick",
      ecommerce: {
        promoClick: {
          promotions: [
            {
              id: banner.id || "",
              name: banner.name || "",
              creative: banner.creative || "",
              position: banner.position || "",
            },
          ],
        },
      },
      eventCallback: function() {
        document.location = banner.link;
      },
    });
  }
  trackView(path, screenName) {
    if (inBrowser) {
      return this.pushDataLayer({
        event: "content-view",
        "content-name": path,
        "content-view-name": screenName,
      });
    }
  }
  trackEvent({
    event = null,
    category = null,
    action = null,
    label = null,
    value = null,
    noninteraction = false,
    ...rest
  } = {}) {
    return this.noPushDataLayer({
      event: event || "interaction",
      target: category,
      action: action,
      "target-properties": label,
      value: value,
      "interaction-type": noninteraction,
      ...rest,
    });
  }
}
