import lazyLoadView from "./lazyLoadView";

import MinimalLayout from "@layouts/minimal.vue";
import MainLayout from "@layouts/main.vue";

import Checkout from "@pages/checkout.vue";
import Vendedor from "@pages/vendedor.vue";

export default [
  {
    path: "/",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/vendedor",
    name: "Vendedor",
    component: Vendedor,
  },
  // Minimal com breadcrumb & footer
  {
    path: "/",
    component: MinimalLayout,
    children: [
      // {
      //   path: "/atoms",
      //   name: "Atoms",
      //   component: () => lazyLoadView(import("@pages/atoms.vue")),
      //   meta: { noBreadcrumb: false, noFooter: false, isMinimal: true },
      // },
      {
        path: "/consulta-cobertura",
        name: "ConsultaCobertura",
        component: () => lazyLoadView(import("@pages/consulta-cobertura.vue")),
        meta: { noBreadcrumb: false, noFooter: false, isMinimal: true },
      },
      {
        path: "/escolha-plano",
        name: "EscolhaPlano",
        component: () => lazyLoadView(import("@pages/escolha-plano.vue")),
        meta: {
          noBreadcrumb: false,
          noFooter: true,
          isMinimal: true,
          noPadding: true,
          hasCarousel: true,
        },
      },
      {
        path: "/sem-cobertura",
        name: "SemCobertura",
        component: () => lazyLoadView(import("@pages/sem-cobertura.vue")),
        meta: { noBreadcrumb: true, noFooter: false, isMinimal: true },
      },
      {
        path: "/sem-cobertura-lead-enviado",
        name: "SemCoberturaLeadEnviado",
        component: () => lazyLoadView(import("@pages/lead-enviado.vue")),
        meta: { noBreadcrumb: true, noFooter: true, isMinimal: true },
      },
      {
        path: "/campanha-desativada",
        name: "CampanhaDesativada",
        component: () => lazyLoadView(import("@pages/campanha-desativada.vue")),
        meta: { noBreadcrumb: true, noFooter: true, isMinimal: true },
      },
    ],
  },

  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "/pagamento",
        name: "Pagamento",
        component: () => lazyLoadView(import("@pages/pagamento.vue")),
        meta: { noBreadcrumb: false, noFooter: false, hasSidebar: true },
      },
      {
        path: "/complementar-dados",
        name: "ComplementarDados",
        component: () => lazyLoadView(import("@pages/complementar-dados.vue")),
        meta: { noBreadcrumb: false, noFooter: false, hasSidebar: true },
      },
      {
        path: "/endereco",
        name: "Endereco",
        component: () => lazyLoadView(import("@pages/endereco.vue")),
        meta: { noBreadcrumb: false, noFooter: false, hasSidebar: true },
      },
      {
        path: "/parabens",
        name: "Parabens",
        component: () => lazyLoadView(import("@pages/parabens.vue")),
        meta: { noBreadcrumb: true, noFooter: true },
      },
      {
        path: "/dados-pessoais",
        name: "DadosPessoais",
        component: () => lazyLoadView(import("@pages/dados-pessoais.vue")),
        meta: { noBreadcrumb: false, noFooter: false },
      },
    ],
  },
  {
    path: "/fatura",
    redirect: "/pagamento",
  },
  {
    path: "*",
    redirect: "/",
  },
];
