const domainToProjectType = {
  "carrinho.planostim.com.br": "TimDealer",
  "assine.planostimultrafibra.com.br": "TimDealer",
  "www.fibradatim.com": "Live",
  "carrinho.planostimultrafibra.com.br": "Live",
  "live-st1-t892.vercel.app": "Live",
  "live-st1-t1008.vercel.app": "Live",
  "tim-dealer-st1-t892.vercel.app": "TimDealer",
  "tim-dealer-st1-t1008.vercel.app": "TimDealer",
};

export default function getDomain(domain) {
  return domainToProjectType[domain] ?? null;
}
