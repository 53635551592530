export const DEFAULT_API_URLS = {
  LOCAL: process.env.VUE_APP_API_ENDPOINT,
  PRODUCTION: process.env.VUE_APP_API_ENDPOINT,
  DEVELOPMENT: process.env.VUE_APP_API_ENDPOINT,
};

export const CATALOG_API_URLS = {
  LOCAL: process.env.VUE_APP_API_CATALOG,
  PRODUCTION: process.env.VUE_APP_API_CATALOG,
  DEVELOPMENT: process.env.VUE_APP_API_CATALOG,
};

export const INDIQUEI_API_URLS = {
  LOCAL: process.env.VUE_APP_API_INDIQUEI,
  PRODUCTION: process.env.VUE_APP_API_INDIQUEI,
  DEVELOPMENT: process.env.VUE_APP_API_INDIQUEI,
};
