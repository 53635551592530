import axios from "axios";

import store from "@state/store";
import { SET_API_CALL_IN_PROGRESS } from "@state/rootStore";

const HTTPClient = axios.create({
  baseURL: process.env.VUE_APP_CHECKOUT_TIM_API_ENDPOINT,
});

HTTPClient.interceptors.request.use(
  config => {
    store.commit(SET_API_CALL_IN_PROGRESS, true);
    config.headers["access_token"] = process.env.VUE_APP_CHECKOUT_TIM_API_KEY;
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.timeout = 15000;
    return config;
  },
  response => Promise.reject(response)
);

HTTPClient.interceptors.response.use(
  response => {
    store.commit(SET_API_CALL_IN_PROGRESS, false);
    return response;
  },
  error => {
    store.commit(SET_API_CALL_IN_PROGRESS, false);
    return Promise.resolve(error);
  }
);

export { HTTPClient };
